import { countTo } from "../lib/countTo"

export function initPledgeProgressBar() {
  const progressBar = document.querySelector(".progress-bar")

  function handleProgressBarIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        const progressElement = entry.target.querySelector(".progress")
        const percentageElement = entry.target.querySelector(".percentage")
        const percentage = entry.target.dataset.percentage
        progressElement.classList.add("animate")
        progressElement.style.width = `${percentage}%`
        countTo(percentageElement, percentage, 1600)
        observer.unobserve(entry.target)
      }
    })
  }

  if (progressBar) {
    const observer = new IntersectionObserver(handleProgressBarIntersection)
    observer.observe(progressBar)
  }
}
