const easeOutQuad = (t) => t * (2 - t)
const raf = window.requestAnimationFrame

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function countTo(targetElement, value, ms) {
  let start
  const next = (timestamp) => {
    if (start === undefined) {
      start = timestamp
    }
    const progress = (timestamp - start) / ms

    const current = Math.min(value, Math.ceil(easeOutQuad(progress) * value))

    targetElement.innerHTML = current > 0 ? formatNumber(current) : value

    if (progress < 1) {
      raf(next)
    }
  }
  raf(next)
}
