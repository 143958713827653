export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export const qs = (s, el = document) => el.querySelector(s)
export const qsa = (s, el = document) =>
  Array.prototype.slice.call(el.querySelectorAll(s))
