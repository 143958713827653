import "./css/reset.css"
import "./css/colors.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/layout.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/hero.css"
import "./css/details.css"
import "./css/plugins.css"
import "./css/supporters.css"
import "./css/shop.css"

import "./css/cookiecontrol.css"
import "./css/pledge.css"

import "./css/admin.css"
import "./css/variables.css"

import { onReady } from "./js/utils/dom"
import { initScrolledNav, initToggleNav } from "./js/components/nav"
import { initPledgeProgressBar } from "./js/components/pledge"

onReady(initToggleNav)
onReady(initScrolledNav)
onReady(initPledgeProgressBar)

onReady(() => {
  if (document.querySelector(".scroll-to-content")) {
    location.hash = "#content-anchor"
  }
})
